import { Divider } from 'semantic-ui-react'
import SiteMenu from './SiteMenu';
import LoadStory from './LoadStory';
import StoryList from './StoryList';
import LongStory from './LongStory';
import Footer from  './Footer';
import {
  BrowserRouter as Router,
  Switch, Route } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";


import Admin from './Admin';
function Routing(props) {
  const isAuthenticated = useIsAuthenticated();
  
  
  return(
    <Router>
        <SiteMenu/>     
      <main>
      
    <Switch>
      
      <Route path="/about">
        ABOUT
        </Route>
      {isAuthenticated ?    
      <Route path="/admin" component={Admin}/> :""}
      {isAuthenticated ?    
      <Route path="/submit/:sid?" component={LoadStory}/>:""}
      {isAuthenticated ?
      <Route path="/submit" component={LoadStory}/>:""}
      
      <Route path="/s/:sid" component={LongStory}/>
      <Route path="/t/:tname" component={StoryList}/>
      <Route path="/" component={StoryList}/>
    </Switch>
    
    </main>
    <Divider section />
    <Footer/>
  </Router>
    
    
    
  );
}

export default Routing;
