import React from "react";
import { gql,  useMutation } from '@apollo/client';
import { Spinner, Form, Button} from 'react-bootstrap'
import { useForm } from "react-hook-form";
import {Config} from './Config';




const NEWSTORY=gql`mutation($tag:String,$title: String, $text: String, $imgurl:String){
    addNewStory(tag:$tag, text: $text, title: $title,imgurl: $imgurl)
  }`;

  const UPDATESTORY=gql`mutation($id:ID $tag:String,$title: String, $text: String, $imgurl:String){
    updateStory(id:$id, tag:$tag, text: $text, title: $title, imgurl: $imgurl)
  }`;
  const tagoptions=Config.tags.map((t)=>
  <option value={t}>{t}</option>);
  function AddStory(props) {;
  
    const { register, handleSubmit, formState: { errors } } = useForm();
  const STORY_MUT=props.story?UPDATESTORY:NEWSTORY;
  const [addNewStory, { data, loading, error }] = useMutation(STORY_MUT);
  const dv={
    tag:props.story?props.story.tag:"post",
    title:props.story?props.story.title:"",
    text:props.story?props.story.text:"",
    imgurl:props.story?props.story.imgurl:Config.defimg
  };
  const onSubmit = data => {
    let v={tag:data.tag, title:data.title, text:data.text,imgurl: data.imgurl};
    if(props.story){
      v.tag=dv.tag;
      v.id=props.story.id;
    }
    addNewStory({variables:v});
  };

  
  if (loading) return <Spinner/>;
  if (error) return <p>Error :(</p>;
  if (data) return <span>Done</span>;
   
  return (
    <div>
      {errors?
      <span>Errors</span>:
      ""}
      <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group controlId="formTag" className="mb-3">
        <Form.Label>Tag</Form.Label>
        {!props.story?
        <select {...register("tag", { required: true})}>
          {tagoptions}
        </select>
        :
        <Form.Control type="text" readonly disabled {...register("tag")} value={props.story.tag}/>
      }
       </Form.Group>
       <Form.Group controlId="formImgurl" className="mb-3">
        <Form.Label>ImgUrl</Form.Label>
        <Form.Control type="text" defaultValue={dv.imgurl} name="imgurl" 
         {...register("imgurl", { required: true})} />
       </Form.Group>
       <Form.Group controlId="formTitle" className="mb-3">
        <Form.Label>Title</Form.Label>
        <Form.Control type="text" defaultValue={dv.title} name="title" placeholder="Title"
         {...register("title", { required: true})} />
       </Form.Group>
       

        <Form.Group controlId="formText" className="mb-3">
          <Form.Label>Text</Form.Label>
          <Form.Control
            as="textarea"
            name="text"
            defaultValue={dv.text}
            rows={10}
            {...register("text", { required: true})}
          />
         
        </Form.Group>
        <Button primary type="submit">Add Story</Button>
      </Form>
      
    </div>
  );
  
  
}
export default AddStory
