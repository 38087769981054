
export default function Footer() {
  
  return(
    <footer className="bg-black footer">
    
    <div className="footer-bottom border-style top light">
        <div className="container">
            <div className="row">
                <div className="col-md-6 my-3">
                    <div className="nav justify-content-center justify-content-md-start">
                        <a className="icon icon-sm icon-primary rounded-circle me-2" href="/">
                            <i className="fab fa-facebook-f"></i>
                        </a>
                        <a className="icon icon-sm icon-primary rounded-circle me-2" href="/">
                            <i className="fab fa-twitter"></i>
                        </a>
                        <a className="icon icon-sm icon-primary rounded-circle me-2" href="/">
                            <i className="fab fa-instagram"></i>
                        </a>
                        <a className="icon icon-sm icon-primary rounded-circle me-2" href="/">
                            <i className="fab fa-linkedin-in"></i>
                        </a>
                    </div>
                </div>
                <div className="col-md-6 my-3 text-center text-md-end text-white-50">
                    <p className="m-0">© Copyright 2021 rumormiller.com. All rights reserved.</p>
                </div>r
            </div>
        </div>
    </div>
</footer>
  );
}


