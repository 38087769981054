import Dev from "./Dev";



  function Admin() {
  
   
  return (
    <div>
      You're logged in!
      <Dev></Dev>
    </div>
  );
  
  
}
export default Admin
