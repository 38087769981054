
import { useIsAuthenticated } from "@azure/msal-react";
import {Config} from './Config';
import ShareButton from "./ShareButton";
import {Link} from "react-router-dom";
export default function Story(props) {
  const isAuthenticated = useIsAuthenticated();  
  const url="/s/"+props.story.id;

  
  return(
    <div className="col-md-4 col-sm-6 grid-item">
      <div className="border hover-scale link-effect-box">
        <div className="post-card-img hover-scale-in">
          <Link to={url} >
            <img src={props.story.imgurl?props.story.imgurl:Config.defimg} title="" alt={props.story.title} className="card-img-top"/>
          </Link>
          
        </div>
      
       
      </div>
      <div className="p-3 bg-white mt-n4 position-relative mx-4">
          <div className="mb-1 small">
             <Link className="text-primary font-w-500" to={"/t/"+props.story.tag}>{props.story.tag}</Link>
          </div>
          <h4 className="h5 mb-3"><a className="text-reset link-effect-in" href={url}>{props.story.title}</a></h4>
            <div className="nav dark-link small">
              <span className="me-4"><i className="far fa-clock me-2"></i> {props.story.posted}</span>
              <ShareButton title={props.story.title} url={url}/>
              {isAuthenticated?
              <span className="me-4"><a href={"/submit/"+props.story.id}><i className="far fa-edit me-2"></i> edit</a></span>
              :""}   
        </div>
          
      </div>
     
    </div>
  );
}