import { Container, Navbar,Nav} from 'react-bootstrap';
import {Config} from '../Config';
import { SignInButton } from '../SignInButton';
import { SignOutButton } from '../SignOutButton';
import { useIsAuthenticated } from "@azure/msal-react";


const tags=Config.tags;
const tm=tags.map((t)=>
 <Nav.Link className="mr-auto my-2 my-lg-0" href={"/t/"+t} key={t} >
      {t}
  </Nav.Link>
);


const authLinks=[
    {name:"submit",link:'/submit'},
    {name:"dev",link:'/admin'},
  ];
  const am=authLinks.map((a)=>
    <Nav.Link key={a.name} href={a.link}  >
        {a.name}
    </Nav.Link>
  );

export default function MenuHeader(){
    const isAuthenticated = useIsAuthenticated();

    return (
        
        
        <Navbar  collapseOnSelect  expand="lg" bg="light" variant="light" >
                <Container>
                <Navbar.Toggle className="m-0 p-0"  aria-controls="responsive-navbar-nav" />
                <Navbar.Brand  className="align-top" href="/">
                       <img width="200" src="/static/img/logo.png" title="" alt="brand"/>
                 </Navbar.Brand>
                 <Navbar.Collapse className="ml-4 z-index-3 py-0"   id="responsive-navbar-nav">
                    <Nav className="ml-3 me-auto z-index-3 py-0">
                        {tm}
                    </Nav>
                    <Nav className="ml-3 me-auto">
                        <Nav.Item>     
                            { isAuthenticated ? <SignOutButton/> : <SignInButton /> }
                        </Nav.Item>
                        { isAuthenticated ? am : "" }
                    </Nav>
                </Navbar.Collapse>
                </Container>
            </Navbar>
        
        
    );
}