
import { useQuery, gql} from "@apollo/client";
import { useParams } from 'react-router';
import { Spinner } from "react-bootstrap";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import ShareButton from "./ShareButton";

const STORY_QUERY = gql`
query ($sid:String){
  story:getStoryByID(storyId: $sid) {
    id title text tag imgurl posted
  }
}
`;

export default function LongStory() {

  const { sid } = useParams();
const { loading, error, data } = useQuery(STORY_QUERY,{
  variables: { sid },
});
if (loading) return <Spinner/>;
if (error) return <p>Error :(</p>;
  
  return (
    <div className="py-5 py-lg-7 single-post-type-01">
    <div className="container">
        <div className="row g-3">
            <div className="col-lg-8">
                <div className="card">
                    <div className="card-body">
                        <article>
                            <div className="single-post-heading">
                                <h6 className="text-primary font-w-600"><a href={"/t/"+data.story.tag}>{data.story.tag}</a></h6>
                                <h2 className="m-0 h2">{data.story.title}</h2>
                                <ShareButton tite={data.story.title} url={window.location.pathname } />
                               
                            </div>
                            <div className="single-post-thumb py-5">
                               {data.story.imgurl?<img src={data.story.imgurl} title="" alt=""/>
                               :""}
                            </div>
                            <div className="border-top pt-3 mt-3">
                                    <div className="nav small">
                                        <span className="pe-3">{data.story.posted}</span>
                                      
                                    </div>
                                </div>
                            <div className="single-post-content">
                              <ReactMarkdown children={data.story.text} remarkPlugins={[remarkGfm]}  />
                            </div>
                           <div className="row border-top mt-4 g-0 pt-3">
                                <div className="col-6 pe-3">
                                    <div className="d-flex">
                                        <div className="avatar avatar-lg d-none d-lg-inline-flex rounded-circle">
                                            <img src="static/img/avatar-1.jpg" title="" alt=""/>
                                        </div>
                                      
                                    </div>
                                </div>
                                
                            </div>
                        </article>
                    </div>
                    <div className="card-footer">
                        <div className="article-share d-flex align-items-center">
                            <h6 className="text-center m-0">215 Shares</h6>
                            <div className="nav justify-content-center ms-auto">
                                <a className="icon icon-primary rounded-circle me-2" style={{background: "#455fa1"}} href="/">
                                    <i className="fab fa-facebook-f"></i>
                                </a>
                                <a className="icon icon-primary rounded-circle me-2" style={{background: "#58abf5"}} href="/">
                                    <i className="fab fa-twitter"></i>
                                </a>
                                <a className="icon icon-primary rounded-circle me-2" style={{background: "#cd1d1f"}} href="/">
                                    <i className="fab fa-pinterest"></i>
                                </a>
                                <a className="icon icon-primary rounded-circle me-2" style={{background: "#1980be"}} href="/">
                                    <i className="fab fa-linkedin-in"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
               
              
              
              
              
               
            
          
           
              
                        <span className="d-block w-40px h-4px bg-primary mx-auto rounded-3"></span>
                    </div>
                 
                     
                    </div>
                </div>
             </div>
          
        
    
  );
}


