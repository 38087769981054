
import Frame from './components/Frame.jsx';
import { PublicClientApplication } from "@azure/msal-browser";
import {  MsalProvider } from "@azure/msal-react";




import { msalConfig } from "./authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

function App() {
 
 
  return (<MsalProvider instance={msalInstance}>
              <Frame/>
            </MsalProvider>
  );
}

export default App;
