const tags=['news','post','cartoon'];
const prod = {
  tags:tags,
  uri: '/api/graphql',
  defimg: '/static/img/blog-1.jpg',
  baseurl:'https://rumormiller.com',
 }; 
 const dev = {
    tags:tags,
    uri: 'http://localhost:7071/api/graphql',
    defimg: '/static/img/blog-1.jpg',
    baseurl:'http://localhost:3000',
 }; 
 export const Config = process.env.NODE_ENV === 'development' ? dev: prod;

