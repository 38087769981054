export const msalConfig = {
    auth: {
      clientId: "04bdc541-3044-4ab3-be38-3de16d755bb6",
      authority: "https://login.microsoftonline.com/e0b06086-88b6-41d1-9d71-dd7b5d86c42d",
      redirectUri: "/admin",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["User.Read"]
  };