import { gql, useQuery } from '@apollo/client';
import { Spinner } from 'react-bootstrap'
import { useParams } from 'react-router';
import AddStory from './AddStory'
const STORY_QUERY = gql`
query ($sid:String){
  story:getStoryByID(storyId: $sid) {
    id tag title text imgurl
  }
}
`;



  function LoadStory(){
    let { sid } = useParams();
    const { loading, error, data } = useQuery(STORY_QUERY,{
      variables: { sid },
    });
    if (loading) return <Spinner/>;
    if (error) return <p>Error :(</p>;
    if (data) return <AddStory story={data.story}/>
    

  }

  
export default LoadStory
