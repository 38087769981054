import { Container } from 'semantic-ui-react'

import { useQuery, gql} from "@apollo/client";
import { Loader} from 'semantic-ui-react'


const ID_QUERY = gql`
query {
  me:whoami
  tags:getAllTags
}
`;


function Dev() {
  
  const { loading, error, data } = useQuery(ID_QUERY);
  if (loading) return (
    <Container>
    <Loader />
    </Container>
  );
  if (error) return <p>Error :(</p>;
  
  let t=data.tags.map((t)=><h1>{t}</h1>);
  return(
    <Container>
      {data.me}
      {t}
  </Container>
  );
}

export default Dev;
