import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { useMsal } from "@azure/msal-react";
import Routing from './Routing';
import {Config} from './Config';

function Frame() {
  
  const { accounts } = useMsal();
  const name = accounts[0] && accounts[0].name;
  localStorage.setItem("sad",name);
  
  const httpLink = createHttpLink({
    uri:Config.uri
    
  });
  
  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('sad');
    
    
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      }
    }
  });
  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  });
 
  return (
    <ApolloProvider client={client}>
          
          <Routing/>
          
    </ApolloProvider>
  );
}

export default Frame;
