import { useParams } from 'react-router';
import { Spinner , Container } from 'react-bootstrap';
import Story from "./Story";
import { useQuery, gql} from "@apollo/client";


const ALLSTORY_QUERY=gql`
query {
  stories:getAllStories {
    title
    text posted
    id tag imgurl
  }
}
`;

const STORY_QUERY = gql`
query ($tname:String) {
  stories:getAllStory(tagname:$tname) {
    title
    text posted
    id tag imgurl
  }
}
`;


export default function StoryList() {
  let { tname } = useParams();
  const QUERY=tname?STORY_QUERY:ALLSTORY_QUERY;
  const { loading, error, data } = useQuery(QUERY,{variables:{tname:tname}});
  if (loading) return (
  
    <Container>
      <Spinner />
    </Container>
  
  );
  if (error) return <p>Error :(</p>;
  
  let smap=data.stories.map((s)=>{return (<Story key={s.id} story={s}/>);});;
  return(
    
   <Container>
    <div className="post-masonry row g-4">
    
    
    
            {smap}
    
    </div>
  </Container>
  
  );
}


