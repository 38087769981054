import { Button } from "react-bootstrap";
import React, { useState } from 'react';
import {Config} from './Config';

    
    

export default function ShareButton(props){
    const shareClick = (e) => function(e){
        let shareData={
                 text: props.title,
                 title:props.title,
                 url: Config.baseurl + props.url
                };
             
                 window.navigator.share(shareData)
               .then()
               .catch(setShare(false));
     };
 
     const deskshareClick=()=>function(){
         console.log("hi "+props.title);
     }
   
    const [share, setShare] = useState(true);
    const title=props.title ;
    const url=props.url;
    
    if(share){
        return <Button className="me-4" variant="link" onClick={shareClick()}><i className="far fa-share-square me-2"></i> share</Button>
    }
    return <Button className="me-4" variant="link" onClick={()=>deskshareClick(title,url)}><i className="far fa-share-square me-2"></i> SHARE</Button>;
};